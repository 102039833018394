import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Pagination from 'react-bootstrap/Pagination';

import StatusSuccess from '../../../images/dashboard/status-success.png';
import StatusCancel from '../../../images/dashboard/status-cancel.png';

import MainPagetitle from '../../layouts/MainPagetitle';
import OrderHistoryPreview from './OrderHistoryPreview';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAstrologerOrderHistory } from '../../../Redux/Slice';


const AstrologerOrderHistoryList = () => {
    const dispatch = useDispatch();
    const token = localStorage.getItem('admin-auth-token');

    const { AstrologerOrderHistory } = useSelector((state) => state.Astromind);
    const { id } = useParams();


    const [show, setShow] = useState(false);
    const [type, setType] = useState("call");
    const [previewData, setPreviewData] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        let page = currentPage ? currentPage : 1;
        dispatch(GetAstrologerOrderHistory({ token, page, type, id }))
    }, [dispatch, token, type, currentPage, id]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (AstrologerOrderHistory?.data?.pagination?.currentpage) {
            setCurrentPage(AstrologerOrderHistory?.data?.pagination?.currentpage);
        }
    }, [AstrologerOrderHistory]);

    // useEffect(() => {
    //     let page = currentPage ? currentPage : 1 ;
    //     dispatch(GetOrderHistory({ token, page, type }));
    // }, [dispatch, token, currentPage, type]);

    console.log(AstrologerOrderHistory);
    const PreviewCall = (data) => {
        setPreviewData(data);
        handleShow();
    }

    const changePage = (value) => {
        let page = value;
        dispatch(GetAstrologerOrderHistory({ token, page, type, id }));
    }

    const nextPage = () => {
        const page = Number(currentPage) + 1;
        if (page <= AstrologerOrderHistory?.data?.pagination?.totalPage) {
            dispatch(GetAstrologerOrderHistory({ token, page, type, id }));
        }
    }

    const previousPage = () => {
        const page = Number(currentPage) - 1;
        if (page >= 1) {
            dispatch(GetAstrologerOrderHistory({ token, page, type, id }));
        }
    }

    return (
        <>
            <MainPagetitle pageTitle={type == "call" ? "Call Logs" : "Chat Logs"} parentTitle="Order History" />
            <Container fluid>
                <Row className='d-flex'>
                    <Col lg={12} className='d-flex justify-content-center gap-3'>
                        <button
                            className={`approval-btn ${type == "call" && "bg-yellow text-white"}`}
                            onClick={() => { setType("call") }}
                        >
                            Call
                        </button>
                        <button
                            className={`approval-btn ${type == "chat" && "bg-yellow text-white"}`}
                            onClick={() => { setType("chat") }}
                        >
                            Chat
                        </button>
                    </Col>
                    <Col lg={12} className='call-list-main'>
                        <div className='call-list-title'>
                            <h3 className='fw-600'>{type == "call" ? "Call Logs" : "Chat Logs"}</h3>
                        </div>
                        {AstrologerOrderHistory?.data?.data?.length > 0 ?
                            <Col lg={12} className='call-list mb-3'>
                                <table className={show ? "table-60" : "table-100"} >
                                    <tr>
                                        <th>Date & Time</th>
                                        <th>Order ID</th>
                                        <th className='mobile-hide'>Name</th>
                                        <th className={show ? "hide-list" : null}>Duration</th>
                                        {/* <th className={show ? "hide-list mobile-hide" : "mobile-hide"}>Total Rate</th> */}
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                    {AstrologerOrderHistory?.data?.data?.map((data, index) => {
                                        let indianTime = new Date(data?.order?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                        let Minute = data?.order?.type == "call" ? Math.floor(data?.order?.calls[0]?.ConversationDuration / 60) : data?.order?.type == "chat" ? Math.floor(data?.chat[0]?.duration / 60) : 0;
                                        let seconds = data?.order?.type == "call" ? data?.order?.calls[0]?.ConversationDuration % 60 : data?.order?.type == "chat" ? Math.floor(data?.chat[0]?.duration % 60) : 0;
                                        return (
                                            <>
                                                <tr>
                                                    <td>{indianTime}</td>
                                                    <td>{data?.order?.id}</td>
                                                    <td className='mobile-hide'>{data?.order?.subuser?.name}</td>
                                                    <td className={show ? "hide-list" : null}>{data?.order?.calls?.length > 0 ? <>{`${Minute} min ${seconds} sec`}</> : data?.chat?.length > 0 ? <>{`${Minute} min ${seconds} sec`}</> : "none"}</td>
                                                    {/* <td className={show ? "hide-list mobile-hide" : "mobile-hide"}>₹ {data.rate}</td> */}
                                                    {/* <td>{data?.order?.calls?.Status === 'completed' ? <img src={StatusSuccess} alt='success' /> : <img src={StatusCancel} alt='cancel' />}</td> */}
                                                    {data?.order?.type == "call" ?
                                                        <td>{data?.order?.calls[0]?.Status == 'completed' ? <img src={StatusSuccess} alt='success' /> : <img src={StatusCancel} alt='cancel' />}</td>
                                                        : data?.order?.type == "chat" ?
                                                            <td>{data?.chat[0]?.status == 'completed' ? <img src={StatusSuccess} alt='success' /> : <img src={StatusCancel} alt='cancel' />}</td>
                                                            : null
                                                    }
                                                    <td className='view' onClick={() => PreviewCall(data)}>view details</td>

                                                </tr>
                                            </>
                                        )
                                    })}
                                </table>
                            </Col>
                            :
                            <>
                                <div className='no-data'>
                                    <p>No sufficient data available!</p>
                                </div>
                            </>
                        }
                        <OrderHistoryPreview
                            show={show}
                            handleClose={handleClose}
                            data={previewData}
                        />
                    </Col>
                    {AstrologerOrderHistory?.data?.pagination?.totalPage > 1 ?
                        <Col lg={12} className='d-flex justify-content-center mt-3 pagination'>
                            <Pagination className="px-4">
                                <Pagination.Prev onClick={() => previousPage()} />

                                {[...new Array(AstrologerOrderHistory?.data?.pagination?.totalPage)].map((arr, index) => {
                                    return (
                                        <Pagination.Item
                                            onClick={() => changePage(index + 1)}
                                            key={index + 1}
                                            active={index + 1 === Number(currentPage)}
                                        >
                                            {index + 1}
                                        </Pagination.Item>
                                    )
                                })}
                                <Pagination.Next onClick={() => nextPage()} />
                            </Pagination>
                        </Col>
                        : null
                    }
                </Row>
            </Container>
        </>
    )
}

export default AstrologerOrderHistoryList;