import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Swal from 'sweetalert2';

import { IoArrowBackOutline } from "react-icons/io5";

import MainPagetitle from "../../layouts/MainPagetitle";
import ChatPage from "./ChatPage";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetAllTickets, UpdateTicketStatus, successMessage, errorMessage } from "../../../Redux/Slice";

import io from "socket.io-client";

const socket = io('https://dev-astromind-api.astromindcounsel.com', {
  withCredentials: true,
  transports: ['websocket', 'polling'],
  query: {
    who: 'admin'
  }
});

function SupportChat() {
  const { success, errors, Tickets } = useSelector(
    (state) => state.Astromind
  );
  const dispatch = useDispatch();
  const token = localStorage.getItem("admin-auth-token");
  const [key, setKey] = useState('user');

  const [person, setPerson] = useState([]);
  const [ticketId, setTicketId] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [updateChatStatus, setUpdateChatStatus] = useState(null);
  const isLaptop = window.innerWidth > 950 ? true : false;

  const handleJoinChat = (ticket) => {
    setPerson(ticket);
    setIsChatOpen(true);
    socket.emit("joinsupport", { room: ticket?.room });
    setTicketId(ticket?.id);
  }

  const handleUpdateStatus = () => {
    let ticketStatus = updateChatStatus ?
      updateChatStatus
      : person?.status === 'pending' ?
        'open'
        : person?.status === 'open' ?
          'close'
          : null;
    dispatch(UpdateTicketStatus({ token, ticketId: person?.id, ticketStatus }))
  }
  useEffect(() => {
    dispatch(GetAllTickets({ token: token, role: key }));
  }, [dispatch, token, key]);

  useEffect(() => {
    if (success) {
      if (success?.data?.data?.isUpdated) {
        Swal.fire({
          title: success?.data?.message,
          icon: "success",
          iconColor: "#36AA00",
          confirmButtonColor: "#36AA00",
          confirmButtonText: "Okay",
          timer: 5000
        }).then(() => {
          setUpdateChatStatus(success?.data?.data?.currentStatus === 'close' ? null : success?.data?.data?.currentStatus);
          dispatch(successMessage(''));
          dispatch(GetAllTickets({ token: token, role: key }));
          handleJoinChat(person);
        })
      }
    }
    else if (errors?.message) {
      Swal.fire({
        title: errors?.message,
        icon: "error",
        iconColor: "#CA0505",
        confirmButtonColor: "#CA0505",
        confirmButtonText: "Okay"
      }).then((result) => {
        if (result?.isConfirmed) {
          dispatch(errorMessage({ errors: '' }));
        }
      })
    }
  }, [dispatch, token, key, success, errors, person]);

  return (
    <>
      <MainPagetitle pageTitle="Customer Support" parentTitle="Home" />

      <Container fluid className=" pb-5">
        <Row className="support-chat">
          {
            !isChatOpen || isLaptop ?
              <Col lg={5} className="helf">
                <div className="title">
                  <h5>Help</h5>
                </div>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                >
                  <Tab eventKey="user" title="Users">
                    <div className="helf-inside">
                      {Tickets?.data?.data?.tickets?.map((ticket) => {
                        let indianTime = new Date(ticket?.createdAt).toLocaleString(
                          "en-US",
                          {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          }
                        );
                        return (
                          <div
                            className="ticket"
                            onClick={() => {
                              handleJoinChat(ticket)
                            }}
                          >
                            <div>
                              <p className="fw-bold">{ticket?.user?.name}</p>
                              {/* <p className='fw-bold'>Issue : {ticket?.issue}</p> */}
                              <p className="date">{indianTime}</p>
                            </div>
                            <div>
                              <p
                                className={`status ${ticket?.status === 'pending' ? 'pending' : ticket?.status === 'open' ? 'open' : ticket?.status === 'close' ? 'close ' : null} `}>
                                {ticket?.status === 'pending' ? 'Pending' : ticket?.status === 'open' ? 'Opened' : ticket?.status === 'close' ? 'Closed ' : null}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Tab>
                  <Tab eventKey="astrologer" title="Astrologers">
                    <div className="helf-inside">
                      {Tickets?.data?.data?.tickets?.map((ticket) => {
                        let indianTime = new Date(ticket?.createdAt).toLocaleString(
                          "en-US",
                          {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          }
                        );
                        return (
                          <div className="ticket" onClick={() => handleJoinChat(ticket)}>
                            <div>
                              <p className="fw-bold">{ticket?.astrologer?.name}</p>
                              {/* <p className='fw-bold'>Issue : {ticket?.issue}</p> */}
                              <p className="date">{indianTime}</p>
                            </div>
                            <div>
                              <p
                                className={`status ${ticket?.status === 'pending' ? 'pending' : ticket?.status === 'open' ? 'open' : ticket?.status === 'close' ? 'close ' : null} `}>
                                {ticket?.status === 'pending' ? 'Pending' : ticket?.status === 'open' ? 'Opened' : ticket?.status === 'close' ? 'Closed ' : null}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Tab>
                </Tabs>

              </Col>
              : null
          }
          {
            isChatOpen || isLaptop ?
              <Col lg={7} className="chat-screen">
                <div className="title">
                  {
                    !isLaptop ?
                      <IoArrowBackOutline className='icon' onClick={() => setIsChatOpen(!isChatOpen)} />
                      : null
                  }
                  {
                    isChatOpen ?
                      <>
                        <div className="d-flex justify-content-between px-5">
                          <div>
                            <h5>{person?.user?.name || person?.astrologer?.name}(+{person?.user?.phone || person?.astrologer?.phone})</h5>
                          </div>
                          <div className="">
                            <button
                              className='btn-primary bg-white px-3'
                              onClick={() => { handleUpdateStatus() }}
                            >
                              {
                                updateChatStatus ?
                                  updateChatStatus === 'open' ? 'Close' : null
                                  : <>
                                    {person?.status === 'pending' ? 'Open' : person?.status === 'open' ? 'Close' : null}
                                  </>
                              }
                            </button>
                          </div>
                        </div>
                      </>
                      : <h5>Support Chat</h5>
                  }
                </div>
                <div className="chat">
                  <ChatPage socket={socket} person={person} ticketId={ticketId} />
                </div>
              </Col>
              : null
          }
        </Row>
      </Container>
    </>
  );
}

export default SupportChat;
