import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import MainPagetitle from '../../layouts/MainPagetitle';

import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetWalletHistory } from '../../../Redux/Slice';

const timePeriod = [
    { value: 'OverAll', label: 'OverAll' },
    { value: 'Today', label: 'Today' },
    { value: 'Yesterday', label: 'Yesterday' },
    { value: 'Last-7', label: 'Last 7 days' },
    { value: 'Last-15', label: 'Last 15 days' },
    { value: 'Last-30', label: 'Last 30 days' },
    { value: 'Current-month', label: 'Current Month' },
    { value: 'Previous-month', label: 'Previous Month' },
    { value: 'Custom', label: 'Custom' },
    { value: ' ', label: ' ' },
]

function WalletHistory() {
    const dispatch = useDispatch();
    const { WalletHistory, Loading } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');
    const { id } = useParams();
    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    const [type, setType] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [balance, setBalance] = useState(null);
    const pageLimit = 15;

    const [time_period, setTime_period] = useState();

    const [dateRange, setDateRange] = useState([null, null]);

    const [startDate, endDate] = dateRange;

    const [start_date, setStart_date] = useState(startDate ? moment(startDate).format('YYYY-MM-DD') : null);
    const [end_date, setEnd_date] = useState(endDate ? moment(endDate).format('YYYY-MM-DD') : null);

    useEffect(() => {
        if (id && pathname) {
            if (pathname.includes("user")) {
                dispatch(GetWalletHistory({ token, id, role: "user", page: currentPage ? currentPage : 1 }));
                setType("user");
            }
            else if (pathname.includes("astrologer")) {
                
                dispatch(GetWalletHistory({ token, id, role: "astrologer", page: currentPage ? currentPage : 1 }));
                setType("astrologer");
            }
        }
    }, [])

    useEffect(() => {
        const today = new Date();

        if (pathname.includes("astrologer")) {
            if (time_period?.value === 'OverAll') {
                dispatch(GetWalletHistory({ token, id, role: "astrologer", page: currentPage ? currentPage : 1, overall: true }));
            }
            else if (time_period?.value === "Today") {
                setStart_date(today);

                let formatStartdate = today ? moment(today).format('YYYY-MM-DD') : null;

                dispatch(GetWalletHistory({ token, id, role: "astrologer", page: currentPage ? currentPage : 1, formatStartdate }));
            }
            else if (time_period?.value === "Yesterday") {
                const yesterday = new Date(today);
                yesterday.setDate(yesterday.getDate() - 1);

                setStart_date(yesterday);

                let formatStartdate = yesterday ? moment(yesterday).format('YYYY-MM-DD') : null;

                dispatch(GetWalletHistory({ token, id, role: "astrologer", page: currentPage ? currentPage : 1, formatStartdate }));
            }
            else if (time_period?.value === "Last-7" || time_period?.value === "Last-15" || time_period?.value === "Last-30") {
                const pastday = new Date(today);
                const pastdayCount = time_period?.value === "Last-7" ?
                    7
                    : time_period?.value === "Last-15" ?
                        15
                        : time_period?.value === "Last-30" ?
                            30
                            : null;

                pastday.setDate(today.getDate() - pastdayCount);

                setStart_date(pastday);
                setEnd_date(today);

                let formatStartdate = pastday ? moment(pastday).format('YYYY-MM-DD') : null;
                let formatEnddate = today ? moment(today).format('YYYY-MM-DD') : null;

                dispatch(GetWalletHistory({ token, id, role: "astrologer", page: currentPage ? currentPage : 1, formatStartdate, formatEnddate }));
            }
            else if (time_period?.value === "Current-month") {
                let month = today.getMonth() + 1;
                let year = today.getFullYear();
                let firstDay = new Date(year, month - 1, 1);
                let lastDay = new Date(year, month, 0);

                setStart_date(today);

                let Month_Start_Date = moment(firstDay).format('YYYY-MM-DD');
                let Month_End_Date = moment(lastDay).format('YYYY-MM-DD');

                dispatch(GetWalletHistory({ token, id, role: "astrologer", page: currentPage ? currentPage : 1, formatStartdate: Month_Start_Date, formatEnddate: Month_End_Date }));
            }
            else if (time_period?.value === "Previous-month") {
                const pastmonth = new Date(today);
                pastmonth.setMonth(today.getMonth() - 1);

                let month = pastmonth.getMonth() + 1;
                let year = pastmonth.getFullYear();
                let firstDay = new Date(year, month - 1, 1);
                let lastDay = new Date(year, month, 0);

                setStart_date(firstDay);

                let Month_Start_Date = moment(firstDay).format('YYYY-MM-DD');
                let Month_End_Date = moment(lastDay).format('YYYY-MM-DD');

                dispatch(GetWalletHistory({ token, id, role: "astrologer", page: currentPage ? currentPage : 1, formatStartdate: Month_Start_Date, formatEnddate: Month_End_Date }));
            }
            else if (time_period?.value === 'Custom') {
                return;
            }
        }
    }, [dispatch, currentPage, token, id, pathname, time_period])

    useEffect(() => {
        if (time_period?.value === 'Custom' && startDate && endDate) {

            let formatStartdate = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
            let formatEnddate = endDate ? moment(endDate).format('YYYY-MM-DD') : null;

            dispatch(GetWalletHistory({ token, id, role: "astrologer", page: currentPage ? currentPage : 1, formatStartdate, formatEnddate }));
        }
    }, [dispatch, token, id, startDate, endDate])

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(WalletHistory?.data?.pagination?.currentpage);

        let availableBalance = Number(WalletHistory?.data?.data?.astrologer?.mywallet);
        let tdsCharge = (availableBalance * 10) / 100;
        let gstCharge = 0;
        let payableAmount = availableBalance - tdsCharge + gstCharge;

        setBalance({
            availableBalance: availableBalance.toFixed(2),
            tdsCharge: tdsCharge.toFixed(2),
            gstCharge: gstCharge.toFixed(2),
            payableAmount: payableAmount.toFixed(2),
        });
    }, [WalletHistory]);

    const handlePageClick = (event) => {
        const page = Number(event.selected) + 1;
        if (type === 'user') {
            dispatch(GetWalletHistory({ token, id, page, role: type }))
        }
        else if (type === 'astrologer') {
            let formatStartdate = start_date ? moment(start_date).format('YYYY-MM-DD') : null;
            let formatEnddate = end_date ? moment(end_date).format('YYYY-MM-DD') : null;

            if (time_period?.value === 'OverAll') {
                dispatch(GetWalletHistory({ token, id, page, role: "astrologer", overall: true }))
            }
            else if ((time_period?.value === 'Current-month' || time_period?.value === 'Previous-month') && start_date) {
                let month = start_date.getMonth() + 1;
                let year = start_date.getFullYear();
                let firstDay = new Date(year, month - 1, 1);
                let lastDay = new Date(year, month, 0);

                let Month_Start_Date = moment(firstDay).format('YYYY-MM-DD');
                let Month_End_Date = moment(lastDay).format('YYYY-MM-DD');

                dispatch(GetWalletHistory({ token, id, page, role: "astrologer", formatStartdate: Month_Start_Date, formatEnddate: Month_End_Date }))
            }
            else if (formatStartdate && formatEnddate) {
                dispatch(GetWalletHistory({ token, id, page, role: "astrologer", formatStartdate, formatEnddate }))
            }
            else if (formatStartdate) {
                dispatch(GetWalletHistory({ token, id, page, role: "astrologer", formatStartdate }))
            }
            else if (startDate && endDate) {
                let formatStartdate = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
                let formatEnddate = endDate ? moment(endDate).format('YYYY-MM-DD') : null;

                dispatch(GetWalletHistory({ token, id, page, role: "astrologer", formatStartdate, formatEnddate }));
            }
            else if (time_period?.value === 'Custom' && startDate && endDate) {    
                let formatStartdate = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
                let formatEnddate = endDate ? moment(endDate).format('YYYY-MM-DD') : null;
    
                dispatch(GetWalletHistory({ token, id, role: "astrologer", page, formatStartdate, formatEnddate }));
            }
            else {
                dispatch(GetWalletHistory({ token, id, page, role: "astrologer" }))
            }
        }
    }

    return (
        <>
            <MainPagetitle pageTitle="Wallet Transactions" parentTitle="Home" />

            <Container fluid>
                <Row className='d-flex list-main'>
                    {Loading ?
                        <>
                            <div className="wrapper">
                                <div className="page-loader">
                                    <div className="loading one"></div>
                                    <div className="loading two"></div>
                                    <div className="loading three"></div>
                                    <div className="loading four"></div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {
                                type == 'astrologer' ?
                                    <Col lg={12} className='d-flex justify-content-end gap-3'>
                                        {
                                            time_period?.value === "Today" || time_period?.value === "Yesterday" ?
                                                <Col lg={3} sm={3} className="d-flex justify-content-end mb-4" >
                                                    <DatePicker
                                                        className="report-date-picker"
                                                        selected={start_date}
                                                        showIcon
                                                        disabled={true}
                                                    />
                                                </Col>
                                                : null
                                        }
                                        {
                                            time_period?.value === "Last-7" || time_period?.value === "Last-15" || time_period?.value === "Last-30" ?
                                                <Col xl={3} sm={3} className="d-flex justify-content-end mb-4" >
                                                    <DatePicker
                                                        className="report-date-picker"
                                                        selectsRange={true}
                                                        startDate={start_date}
                                                        endDate={end_date}
                                                        showIcon
                                                        disabled={true}
                                                    />
                                                </Col>
                                                : null
                                        }
                                        {
                                            time_period?.value === "Current-month" || time_period?.value === "Previous-month" ?
                                                <Col lg={3} sm={3} className="d-flex justify-content-end mb-4" >
                                                    <DatePicker
                                                        className="report-date-picker"
                                                        selected={start_date}
                                                        dateFormat="MM/yyyy"
                                                        showIcon
                                                        disabled={true}
                                                    />
                                                </Col>
                                                : null
                                        }
                                        {
                                            time_period?.value === "Custom" ?
                                                <Col lg={3} sm={3} className="d-flex justify-content-end mb-4" >
                                                    <DatePicker
                                                        placeholderText='select date range'
                                                        className="report-date-picker"
                                                        selectsRange={true}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        onChange={(update) => {
                                                            setDateRange(update);
                                                        }}
                                                        isClearable={true}
                                                        monthsShown={2}
                                                        showIcon
                                                        maxDate={new Date()}
                                                    />
                                                </Col>
                                                : null
                                        }
                                        {
                                            timePeriod.some(period => period.value === time_period?.value) ?
                                                null
                                                :
                                                <Col lg={3} sm={6} className="d-flex justify-content-end mb-0" >
                                                    <DatePicker
                                                        placeholderText='selected date'
                                                        className="report-date-picker"
                                                        selected={start_date}
                                                        showIcon
                                                        disabled={true}
                                                    />
                                                </Col>
                                        }

                                        <Col lg={3} sm={6} className="mb-4" >
                                            <Select
                                                options={timePeriod}
                                                className="custom-react-select"
                                                value={time_period}
                                                onChange={(value) => { setTime_period(value) }}
                                            />
                                        </Col>

                                    </Col>
                                    : null
                            }


                            <Col lg={12} className='list-data mb-3 p-4'>
                                <div className='d-flex justify-content-between p-3' >
                                    <h4>AMC ID : {type === "user" ?
                                        WalletHistory?.data?.data?.user?.amc_id
                                        : type === "astrologer" ?
                                            WalletHistory?.data?.data?.astrologer?.amc_id
                                            : null}
                                    </h4>
                                    <h4>User Name : {type === "user" ?
                                        WalletHistory?.data?.data?.user?.name
                                        : type === "astrologer" ?
                                            WalletHistory?.data?.data?.astrologer?.name
                                            : null}
                                    </h4>
                                </div>
                                {
                                    type == 'astrologer' ?
                                        <Col lg={12} sm={12} className='blance-card mb-4'>
                                            <Col className='text-center'>
                                                <p className='avail-balance'><span>Available Balance :</span> ₹ {balance?.availableBalance ? balance?.availableBalance : '0.00'}</p>
                                            </Col>
                                            <Col className='d-flex flex-wrap justify-content-center gap-3 mt-3'>
                                                <Col lg={3} sm={5}>
                                                    <p><span>TDS : </span> ₹ {balance?.tdsCharge ? balance?.tdsCharge : '0.00'}</p></Col>
                                                <Col lg={3} sm={5}>
                                                    <p><span>GST : </span> ₹ {balance?.gstCharge ? balance?.gstCharge : '0.00'}</p></Col>
                                                <Col lg={4} sm={5}>
                                                    <p >Payable Amount : <span className='fw-bold text-black'> ₹ {balance?.payableAmount ? balance?.payableAmount : '0.00'}</span></p>
                                                </Col>
                                            </Col>
                                        </Col>
                                        : null
                                }


                                {
                                    WalletHistory?.data?.data?.order?.length > 0 ?
                                        <>
                                            <table key='list' className="table-100" >
                                                <thead>
                                                    <tr className='transaction-heading'>
                                                        <th>S No</th>
                                                        <th>Date & Time</th>
                                                        <th>Order ID</th>
                                                        <th>AMC ID</th>
                                                        <th>Duration</th>
                                                        <th>Type</th>
                                                        <th >Total Amount</th>
                                                        <th>Astrologer Share</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {WalletHistory?.data?.data?.order?.map((data, index) => {
                                                        let indianTime = new Date(data?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                                        let Minute = Math.floor(data?.duration / 60);
                                                        let seconds = Math.floor(data?.duration % 60);

                                                        return (
                                                            <>
                                                                <tr className='transaction-data'>
                                                                    <td>
                                                                        {
                                                                            WalletHistory?.data?.pagination?.currentpage === 1 ?
                                                                                <>
                                                                                    {index + 1}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {((WalletHistory?.data?.pagination?.currentpage - 1) * pageLimit) + (index + 1)}
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    <td>{indianTime}</td>
                                                                    <td>{data?.id}</td>
                                                                    <td>{data?.user ?
                                                                        data?.user?.amc_id
                                                                        : data?.astrologer ?
                                                                            data?.astrologer?.amc_id
                                                                            : "none"}
                                                                    </td>
                                                                    <td>{`${Minute} min ${seconds} sec`}</td>
                                                                    <td >{data?.type}</td>
                                                                    <td >₹ {data?.totalrate}</td>
                                                                    <td >₹ {data?.astrologer_share_cost}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </>
                                        :
                                        <div className='no-data pt-5'>
                                            <p>No sufficient data available!</p>
                                        </div>
                                }
                            </Col>


                            {WalletHistory?.data?.pagination?.totalPage > 1 ?
                                <>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={3}
                                        pageCount={WalletHistory?.data?.pagination?.totalPage}
                                        forcePage={WalletHistory?.data?.pagination?.currentpage - 1}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        className='custom-pagination'
                                    />
                                </>
                                : null
                            }
                        </>
                    }

                </Row>
            </Container >
        </>
    )
}

export default WalletHistory;