import { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ReactAudioPlayer from 'react-audio-player';

import { FaFileDownload } from "react-icons/fa";
import { MdDownload, MdArrowBackIos } from "react-icons/md";

import EmptyProfile from '../../../images/dashboard/empty-profile.png';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetChatSession } from '../../../Redux/Slice';

const OrderHistoryPreview = ({ data, show, handleClose }) => {
    const dispatch = useDispatch();
    const { ChatHistory } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');

    const [showChat, setShowChat] = useState(false);

    let indianTime = new Date(data?.order?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
    let StartTime = data?.order?.type == "call" ?
        new Date(data?.order?.calls[0]?.StartTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
        : data?.order?.type == "chat" ?
            new Date(data?.chat[0]?.createdAt).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
            : null;

    let EndTime = data?.order?.type == "call" ?
        new Date(data?.order?.calls[0]?.EndTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
        : data?.order?.type == "chat" ?
            new Date(data?.chat[0]?.endTime).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
            : null;

    let Minute = data?.order?.type == "call" ?
        Math.floor(data?.order?.calls[0]?.ConversationDuration / 60)
        : data?.order?.type == "chat" ?
            Math.floor(data?.chat[0]?.duration / 60)
            : 0;

    let seconds = data?.order?.type == "call" ?
        data?.order?.calls[0]?.ConversationDuration % 60
        : data?.order?.type == "chat" ?
            Math.floor(data?.chat[0]?.duration % 60)
            : 0;

    const viewChatHistory = () => {
        setShowChat(true);
    }

    useEffect(() => {
        if (data?.order?.type == "chat") {
            let messageSessionId = data?.chat[0]?.id;
            dispatch(GetChatSession({ token, messageSessionId }));
        }
    }, [dispatch, data, token]);

    return (
        <>
            <Offcanvas
                className="call-canvas"
                show={show}
                onHide={handleClose}
                placement={window.screen.width < 600 ? 'bottom' : 'end'}
                name={window.screen.width < 600 ? 'bottom' : 'end'}
                backdrop={false}
                scroll={true}
            >
                <Offcanvas.Header
                    closeButton
                    className={showChat ? 'chat-history-header' : null}
                >
                    {showChat ?
                        <Col className='d-flex gap-5'>
                            <div className='back-icon'>
                                <MdArrowBackIos className='icon' onClick={() => setShowChat(false)}
                                />
                            </div>
                            <h4>{data?.order?.subuser?.name}</h4>
                        </Col> : null}
                </Offcanvas.Header>
                <Offcanvas.Body className='chat-history-body'>
                    {showChat ?
                        <Col lg={12} className='mt-5'>
                            <Col lg={12} id="chat-feed" className="d-flex flex-column chat-messages chat-history-message">
                                {
                                    ChatHistory?.data?.data?.map((item, index) => {
                                        const url = item.message;
                                        const imageExtension = url?.split('.').pop().toLowerCase();
                                        const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(imageExtension);
                                        const isFile = ['pdf', 'csv', 'xlsx', 'txt', 'pptx', 'docx', 'doc', 'zip'].includes(imageExtension);

                                        const urlFile = isFile ? new URL(url) : null;
                                        const pathname = isFile ? urlFile.pathname : null;

                                        let messageTime = new Date(item?.createdAt).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

                                        return (
                                            <>
                                                <Col lg={12} className={`d-flex ${item.role === 'astrologer' ? 'justify-content-end' : 'justify-content-start'}`} key={index}>
                                                    {isImage ?
                                                        <div className="chat-image" >
                                                            <img src={item.message} alt="" className="img-fluid" />
                                                            <div className="image-download">
                                                                <p><a href={item.message}>Download <MdDownload className="icon" /></a></p>
                                                                <span>{messageTime}</span>
                                                            </div>
                                                        </div>
                                                        : isFile ?
                                                            <div className="chat-file">
                                                                <div className="file">
                                                                    <a href={item.message}>
                                                                        <FaFileDownload className="icon" />
                                                                    </a>
                                                                </div>
                                                                <div className="file-name">
                                                                    <p>{pathname?.slice(15)}</p>
                                                                    <span>{messageTime}</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="chat-message">
                                                                <p>{item.message}</p>
                                                                <span>{messageTime}</span>
                                                            </div>
                                                    }

                                                </Col>
                                            </>
                                        );
                                    })
                                }

                            </Col>
                        </Col>
                        :
                        <Col className='preview-card'>
                            <div className='d-flex justify-content-between'>
                                <Col lg={8}>
                                    <p className='preview-data'><span>Name :</span>{data?.order?.subuser?.name}</p>

                                    <p className='preview-data'><span>Order ID :</span>{data?.order?.id}</p>

                                    <p className='preview-data'><span>Total Rate :</span>₹ {data?.order?.totalrate}</p>

                                    <p className='preview-data'><span>Your Share :</span>₹ {data?.order?.astrologer_share_cost ? data?.order?.astrologer_share_cost : '0'}</p>

                                    <p className='preview-data'><span>Date :</span>{indianTime}</p>

                                    <p className='preview-data'><span>Start Time :</span> {data?.order?.calls?.length > 0 ? StartTime : data?.chat?.length > 0 ? StartTime : "none"}</p>

                                    <p className='preview-data'><span>End Time :</span> {data?.order?.calls?.length > 0 ? EndTime : data?.chat?.length > 0 ? EndTime : "none"}</p>

                                    <p className='preview-data'><span>Duration :</span> {data?.order?.calls?.length > 0 ? <>{`${Minute} min ${seconds} sec`}</> : data?.chat?.length > 0 ? <>{`${Minute} min ${seconds} sec`}</> : "none"}</p>

                                    <p className='preview-data'><span>Status :</span> {data?.order?.calls?.length > 0 ? data?.order?.calls[0]?.Status : data?.chat?.length > 0 ? data?.chat[0]?.status : "Canceled"}</p>
                                </Col>
                                <Col lg={4} >
                                    <div className='call-profile-img'>
                                        <img src={data?.profile?.file ? data?.profile?.file : EmptyProfile} alt="" className='img-fluid' />
                                    </div>
                                </Col>
                            </div>
                            {data?.order?.calls ?
                                <>
                                    <div className='call-audio'>
                                        <ReactAudioPlayer src={data?.order?.calls ? data?.order?.calls[0]?.RecordingUrl : null} controls />
                                    </div>
                                </>
                                : null
                            }
                            {data?.order?.type == "chat" && ChatHistory?.data?.data?.length > 0 ?
                                <div className='view-chat-btn'>
                                    <button onClick={() => viewChatHistory()}>View Chat</button>
                                </div>
                                : null
                            }
                        </Col>
                    }
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default OrderHistoryPreview;